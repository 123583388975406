import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Button, Table } from "antd";
import { isEmpty, isNil } from "ramda";
import { getCountries, getTimezones } from "store/dropdown/dropdownSlice";
import { getSchoolById, updateSchool } from "store/school/schoolSlice";
import {
  getCurriculumAssignments,
  removeCurriculumAssignment,
  resetCurriculum,
  selectCurriculum,
} from "store/curriculum/curriculumSlice";
import {
  getLocaleAssignments,
  removeLocaleAssignment,
  selectLocale,
} from "store/locale/localeSlice";
import Spinner from "components/Spinner/Spinner.component";
import SchoolForm from "components/SchoolForm/SchoolForm";
import Notification from "components/Notification/Notification.component";
import ErrorMessage from "helpers/ErrorMessage";
import { StyledHeader, StyledEditHeader } from "./SchoolEdit.styles";
import curriculumColumns from "helpers/Columns/curriculumColumns";
import localeColumns from "helpers/Columns/localeColumns";

const SchoolEdit = () => {
  const formEl = useRef(null);
  const { isLoading, currentSchool, updateLoading } = useSelector(
    (state) => state.school
  );
  const {
    countries,
    timezones,
    curriculums,
    isLoading: dropdownLoading,
  } = useSelector((state) => state.dropdown);
  const { curriculumAssignments } = useSelector((state) => state.curriculum);
  const { localeAssignments } = useSelector((state) => state.locale);

  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [error, setError] = useState({});
  const { id } = useParams();
  const assignmentType = 3;

  useEffect(() => {
    const getData = async () => {
      await dispatch(resetCurriculum());
      await dispatch(getCountries());
      await dispatch(getSchoolById({ id }));
      // wait 1000
      // fetch curriculum assignment -> school type = 3, school id
      await dispatch(
        getCurriculumAssignments({ assignmentType, assignmentId: id })
      );
      // wait 1000
      // fetch locale assignment -> school type = 3, school id
      await dispatch(getLocaleAssignments({ assignmentId: id }));
    };
    getData();
  }, [dispatch, id]);

  useEffect(() => {
    if (!isEmpty(countries) && !isNil(currentSchool.countryId)) {
      const { countryId } = currentSchool;
      dispatch(getTimezones({ countryId }));
    }
  }, [dispatch, countries, currentSchool, currentSchool.countryId]);

  const onChangeCountry = (value) => {
    formEl.current.setFieldsValue({ zoneId: null });
    dispatch(getTimezones({ countryId: value }));
  };

  const onFinish = async (values) => {
    const res = await dispatch(updateSchool({ ...values, id }));
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    setEditable(false);
    Notification("schoolUpdated");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onDeleteCurriculum = ({ record }) => {
    dispatch(selectCurriculum(record));
    const message = window.confirm("Do you really want to remove this?");
    if (message === true) {
      dispatch(removeCurriculumAssignment({ id: record.id })).then(() =>
        Notification("curriculumRemoved")
      );
    }
  };

  const onDeleteLocale = ({ record }) => {
    dispatch(selectLocale(record));
    const message = window.confirm("Do you really want to remove this?");
    if (message === true) {
      dispatch(removeLocaleAssignment({ id: record.id })).then(() =>
        Notification("localeRemoved")
      );
    }
  };

  return (
    <div className="site-layout-background">
      <Link to="/schools">Back</Link>
      <StyledEditHeader>
        <h1>{currentSchool.name}</h1>
        <span className="edit-button">
          <Button
            type="secondary"
            onClick={() => {
              setEditable(!editable);
            }}
          >
            {!editable ? "Edit" : "View"}
          </Button>
        </span>
      </StyledEditHeader>
      {dropdownLoading || isLoading ? (
        <Spinner />
      ) : (
        <>
          <ErrorMessage>{error && error.message}</ErrorMessage>
          <SchoolForm
            formEl={formEl}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            updateLoading={updateLoading}
            schoolData={currentSchool}
            editable={!editable}
            formType="EDIT"
            countries={countries}
            onChangeCountry={onChangeCountry}
            timezones={timezones}
          />
          <div>
            <StyledHeader>
              <h2>Curriculums</h2>
              <Button type="primary">
                <Link to={`/schools/${id}/curriculums/assign`}>
                  Assign Curriculums
                </Link>
              </Button>
            </StyledHeader>
            <Table
              rowKey="id"
              dataSource={curriculumAssignments}
              columns={curriculumColumns({
                curriculums,
                onDelete: onDeleteCurriculum,
                schoolId: id,
              })}
            />
          </div>
          <br></br>
          <div>
            <StyledHeader>
              <h2>Locales</h2>
              <Button type="primary">
                <Link to={`/schools/${id}/locales/assign`}>Assign Locales</Link>
              </Button>
            </StyledHeader>
            <Table
              rowKey="id"
              dataSource={localeAssignments}
              columns={localeColumns({
                onDelete: onDeleteLocale,
                schoolId: id,
              })}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SchoolEdit;
