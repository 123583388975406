import { notification } from "antd";

export const condition = {
  login: {
    type: "success",
    message: "Success",
    description: "You're logged in successfully!",
  },
  loginError: {
    type: "error",
    message: "Incorrect login",
    description: "Your username/password is incorrect.",
  },
  logout: {
    type: "success",
    message: "Success",
    description: "You're logged out successfully!",
  },
  schoolAdded: {
    type: "success",
    message: "Success",
    description: "School has been added successfully.",
  },
  schoolUpdated: {
    type: "success",
    message: "Success",
    description: "School has been updated successfully.",
  },
  schoolRemoved: {
    type: "success",
    message: "Success",
    description: "School has been removed successfully.",
  },
  curriculumAdded: {
    type: "success",
    message: "Success",
    description: "Curriculum assign. has been added successfully.",
  },
  curriculumUpdated: {
    type: "success",
    message: "Success",
    description: "Curriculum assign. has been updated successfully.",
  },
  curriculumRemoved: {
    type: "success",
    message: "Success",
    description: "Curriculum assign. has been removed successfully.",
  },
  localeAdded: {
    type: "success",
    message: "Success",
    description: "Locale assign. has been added successfully.",
  },
  localeUpdated: {
    type: "success",
    message: "Success",
    description: "Locale assign. has been updated successfully.",
  },
  localeRemoved: {
    type: "success",
    message: "Success",
    description: "Locale assign. has been removed successfully.",
  },
  invalidRoles: {
    type: "error",
    message: "Invalid Role",
    description:
      "This account is not admin/content admin, please use another account.",
  },
};

const Notification = (currentCondition) => {
  const result = condition[currentCondition];

  notification[result.type]({
    message: result.message,
    description: result.description,
    duration: 3,
  });
};

export default Notification;
