import { Form, Checkbox, Input } from "antd";

const AssignedClass = ({ assignedClasses, onChangeCheckedClassses }) => {
  return (
    <>
      <Form.Item name="checkedClasses" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label="Choose Assigned Class(es)"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
      >
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={onChangeCheckedClassses}
        >
          {assignedClasses &&
            assignedClasses.map((assignedClass) => (
              <div key={assignedClass.id}>
                <Checkbox value={assignedClass.id}>
                  {assignedClass.name}
                </Checkbox>
                <br></br>
              </div>
            ))}
        </Checkbox.Group>
      </Form.Item>
    </>
  );
};

export default AssignedClass;
