/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isNil } from "ramda";
import { useSelector, useDispatch } from "react-redux";
import { refreshToken } from "store/login/loginSlice";
import Dashboard from "components/Dashboard/Dashboard.component";

// Helper
const checkIfLoggedIn = (env, cookies) => {
  let isLoggedIn;
  if (env === "dev") {
    if (!isNil(localStorage.getItem("access_token"))) {
      isLoggedIn = true;
    } else if (isNil(cookies.ia_access_token)) {
      isLoggedIn = false;
    } else {
      isLoggedIn = true;
      localStorage.setItem("access_token", cookies.ia_access_token);
      localStorage.setItem("refresh_token", cookies.ia_refresh_token);
    }
  } // env = prod
  else if (!isNil(cookies.ia_access_token)) {
    isLoggedIn = true;
    localStorage.setItem("access_token", cookies.ia_access_token);
    localStorage.setItem("refresh_token", cookies.ia_refresh_token);
  } else if (!isNil(localStorage.getItem("access_token"))) {
    isLoggedIn = true;
  } else {
    isLoggedIn = false;
  }
  return isLoggedIn;
};

const AuthenticatedRoute = ({
  component: Component,
  isParentRoute,
  path,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { userID } = useSelector((state) => state.login);
  const [cookies] = useCookies(["ia_access_token", "ia_refresh_token"]);
  const env =
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_NETLIFY === "true"
      ? "dev"
      : "prod";
  const isLoggedIn = checkIfLoggedIn(env, cookies);
  const loginRoute = "/login";

  useEffect(() => {
    if (userID === null) {
      dispatch(refreshToken());
    }
  }, [dispatch, userID]);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Dashboard>
            <Component {...props} />
          </Dashboard>
        ) : (
          <Redirect
            to={{
              pathname: loginRoute,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
