import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";

// eslint-disable-next-line import/no-cycle
import logout from "helpers/logout";
import Notification from "components/Notification/Notification.component";
const { Sider } = Layout;

const Sidebar = () => {
  // Component States
  const [selected, setSelected] = useState(["1"]);

  // Event Handlers
  const signOut = () => {
    logout();
    Notification("logout");
  };

  return (
    <Sider
      style={{ paddingTop: "100px" }}
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
    >
      <div className="logo" />
      <Menu
        theme="dark"
        selectedKeys={selected}
        onClick={({ key }) => {
          setSelected([key]);
        }}
      >
        <Menu.Item key="1">
          Schools
          <Link to="/schools" />
        </Menu.Item>
        <Menu.Item key="0" onClick={signOut}>
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
