const initialCountry = {
  countryId: null,
  isoCode2: "st",
  name: "string",
  printName: "string",
  isoCode3: "str",
  numberCode: "str",
};

export default initialCountry;
