const getUserRoles = [
  {
    id: 1,
    name: "Student",
    dbName: "PS_Student",
  },
  {
    id: 2,
    name: "Teacher",
    dbName: "PS_Teacher",
  },
];

export default getUserRoles;
