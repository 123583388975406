import React from "react";
import { Form, Input, Button, Select } from "antd";

const schoolTypes = [{ id: 0, name: "Normal" }];

const SchoolForm = ({
  formEl,
  onFinish,
  onFinishFailed,
  schoolData,
  editable,
  countries,
  onChangeCountry,
  timezones,
  updateLoading,
}) => {
  const { name, countryId, code, zoneId, type } = schoolData;

  return (
    <Form
      ref={formEl}
      name="basic"
      initialValues={{
        name,
        code,
        countryId,
        zoneId,
        type,
      }}
      style={{ paddingTop: "50px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item label="Code" name="code" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 6 }}
        rules={[
          {
            required: true,
            message: "Please input name!",
          },
        ]}
      >
        <Input disabled={editable} />
      </Form.Item>
      <Form.Item
        label="Type"
        name="type"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 6 }}
        rules={[
          {
            required: true,
            message: "Please select the type!",
          },
        ]}
      >
        <Select disabled={editable}>
          {schoolTypes.map((schoolType) => (
            <Select.Option key={schoolType.id} value={schoolType.id}>
              {schoolType.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Country"
        name="countryId"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 6 }}
        rules={[
          {
            required: true,
            message: "Please select the coountry!",
          },
        ]}
      >
        <Select
          disabled={editable}
          onChange={onChangeCountry}
          showSearch
          filterOption={(input, { props }) =>
            props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {countries.map((country) => (
            <Select.Option key={country.id} value={country.id}>
              {country.printName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Timezone"
        name="zoneId"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 6 }}
        rules={[
          {
            required: true,
            message: "Please select the timezone!",
          },
        ]}
      >
        <Select
          disabled={editable}
          showSearch
          filterOption={(input, { props }) =>
            props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {timezones.map((timezone) => (
            <Select.Option key={timezone.id} value={timezone.id}>
              {timezone.zoneName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {!editable && (
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={updateLoading}>
            {updateLoading ? "Updating..." : "Submit"}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default SchoolForm;
