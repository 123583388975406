import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Button } from "antd";
import { getCurriculums } from "store/dropdown/dropdownSlice";
import Spinner from "components/Spinner/Spinner.component";
import Notification from "components/Notification/Notification.component";
import ErrorMessage from "helpers/ErrorMessage";
import { StyledEditHeader } from "./CurriculumEdit.styles";
import CurriculumForm from "components/CurriculumForm/CurriculumForm";
import {
  getCurriculumAssignmentById,
  resetEditCurriculum,
  updateCurriculumAssignment,
} from "store/curriculum/curriculumSlice";
import { getSchoolById } from "store/school/schoolSlice";
import { getClasses } from "store/class/classSlice";

const CurriculumEdit = () => {
  const { isLoading: schoolLoading, currentSchool } = useSelector(
    (state) => state.school
  );
  const { classes, currentClass } = useSelector((state) => state.class);
  const { isLoading: dropdownLoading, filteredCurriculums } = useSelector(
    (state) => state.dropdown
  );
  const { isLoading, currentCurriculumAssign } = useSelector(
    (state) => state.curriculum
  );
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [error, setError] = useState({});
  const { id, schoolId } = useParams();

  useEffect(() => {
    dispatch(resetEditCurriculum());
    dispatch(getCurriculums());
    dispatch(getSchoolById({ id: schoolId }));
    dispatch(getClasses({ id: schoolId, type: 1 }));
    dispatch(getCurriculumAssignmentById({ id }));
  }, [dispatch, schoolId, id]);

  const onFinish = async (values) => {
    const res = await dispatch(
      updateCurriculumAssignment({ ...values, id: parseInt(id) })
    );
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    setEditable(false);
    Notification("curriculumUpdated");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to={`/schools/${schoolId}`}>Back</Link>
      <StyledEditHeader>
        <h1>Edit Curriculum</h1>
        <span className="edit-button">
          <Button
            type="secondary"
            onClick={() => {
              setEditable(!editable);
            }}
          >
            {!editable ? "Edit" : "View"}
          </Button>
        </span>
      </StyledEditHeader>
      {schoolLoading || dropdownLoading || isLoading ? (
        <Spinner />
      ) : (
        <>
          <ErrorMessage>{error && error.message}</ErrorMessage>
          <CurriculumForm
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            editable={!editable}
            formType="EDIT"
            curriculums={filteredCurriculums}
            classes={classes}
            currentClass={currentClass}
            curriculumData={{
              name: currentSchool.name,
              assignmentRefId: currentSchool.id,
              ...currentCurriculumAssign,
            }}
          />
        </>
      )}
    </div>
  );
};

export default CurriculumEdit;
