import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchClasses, fetchClassById } from "services/classes";
import { fetchWrapper } from "services/login";
import initialClass from "constants/initialClass";

export const getClasses = createAsyncThunk("classes/getAll", async (params) => {
  try {
    const res = await fetchWrapper(fetchClasses, params);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? "Get all classes failed");
  }
});

export const getClassById = createAsyncThunk(
  "classes/getClassById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchClassById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get class by id failed");
    }
  }
);

const initialState = {
  classes: [],
  classesLoading: false,
  classesError: null,
  users: [],
  isLoading: false,
  isError: null,
  currentClass: initialClass,
};

const classSlice = createSlice({
  name: "classSlice",
  initialState,
  reducers: {
    resetClass: (state) => {
      state.currentClass = initialClass;
      state.users = [];
      state.teachers = [];
    },
  },
  extraReducers: {
    [getClasses.pending]: (state) => {
      state.classesLoading = true;
      state.classes = [];
      state.classesError = null;
    },
    [getClasses.fulfilled]: (state, { payload }) => {
      state.classesLoading = false;
      state.classes = payload;
      state.classesError = null;
    },
    [getClasses.rejected]: (state, action) => {
      state.classesLoading = false;
      state.classes = [];
      state.classesError = action.error.message;
    },
    [getClassById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getClassById.fulfilled]: (state, { payload }) => {
      const { id, levelId, name, users, teachers } = payload;

      state.currentClass = { id, levelId, name };
      // state.users = users;
      state.users =
        teachers.length > 0
          ? users.filter((o1) => teachers.some((o2) => o1.id !== o2.id))
          : users;
      state.teachers = teachers;
      state.isError = null;
      state.isLoading = false;
    },
    [getClassById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
  },
});

export const { resetClass } = classSlice.actions;

const { reducer } = classSlice;
export default reducer;
