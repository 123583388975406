import store from "store/index";
import { history } from "Routes";
import Cookies from "js-cookie";

const logout = () => {
  // clear tokens from localStorage
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("language");
  Cookies.remove("ia_access_token", {
    path: "/",
    domain: "koobits.com",
  });
  Cookies.remove("ia_refresh_token", {
    path: "/",
    domain: "koobits.com",
  });
  store.dispatch({ type: "reset-store" });
  // logout by navigate to login screen
  const loginRoute = "/login";
  history.push(loginRoute);
};

export default logout;
