import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCurriculumAssignment,
  patchCurriculumAssignment,
  deleteCurriculumAssignment,
  fetchCurriculumAssignmentById,
  fetchCurriculumAssignments,
} from "services/assignCurriculums";
import { fetchWrapper } from "services/login";
import {
  initialCurriculumAssign,
  initialEditCurriculumAssign,
} from "constants/initialCurriculumAssign";
import { isEmpty, isNil } from "ramda";

const isNilOrEmpty = (param) => {
  return isNil(param) || isEmpty(param) ? [] : param.split(", ");
};

export const getCurriculumAssignments = createAsyncThunk(
  "curriculumAssignments/getAll",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchCurriculumAssignments, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get all curriculum assign. failed");
    }
  }
);

export const getCurriculumAssignmentById = createAsyncThunk(
  "curriculumAssignments/getById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchCurriculumAssignmentById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get curriculum assign id failed");
    }
  }
);

export const addCurriculumAssignment = createAsyncThunk(
  "curriculums/addCurriculumAssignment",
  async (params) => {
    try {
      const res = await fetchWrapper(createCurriculumAssignment, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Add curriculum assign failed");
    }
  }
);

export const updateCurriculumAssignment = createAsyncThunk(
  "curriculums/updateCurriculumAssignment",
  async (params) => {
    try {
      const res = await fetchWrapper(patchCurriculumAssignment, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update curriculum assign failed");
    }
  }
);
export const removeCurriculumAssignment = createAsyncThunk(
  "curriculums/removeCurriculumAssignment",
  async (params) => {
    try {
      const res = await fetchWrapper(deleteCurriculumAssignment, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Remove curriculum assign failed");
    }
  }
);

const initialState = {
  curriculumAssignments: [],
  isLoading: false,
  isError: null,
  currentCurriculumAssign: initialCurriculumAssign,
};

const curriculumSlice = createSlice({
  name: "curriculumSlice",
  initialState,
  reducers: {
    resetCurriculum: (state) => {
      state.currentCurriculumAssign = initialCurriculumAssign;
    },
    resetEditCurriculum: (state) => {
      state.currentCurriculumAssign = initialEditCurriculumAssign;
    },
    selectCurriculum: (state, { payload }) => {
      state.currentCurriculumAssign = payload;
    },
  },
  extraReducers: {
    [getCurriculumAssignments.pending]: (state) => {
      state.isLoading = true;
      state.curriculumAssignments = [];
      state.isError = null;
    },
    [getCurriculumAssignments.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.curriculumAssignments = payload;
      state.isError = null;
    },
    [getCurriculumAssignments.rejected]: (state, action) => {
      state.isLoading = false;
      state.curriculumAssignments = [];
      state.isError = action.error.message;
    },
    [getCurriculumAssignmentById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getCurriculumAssignmentById.fulfilled]: (state, { payload }) => {
      const { roleFilterIds, levelFilter } = payload;
      const newRoles = isNilOrEmpty(roleFilterIds);
      const newLevels = isNilOrEmpty(levelFilter);

      state.currentCurriculumAssign = {
        ...payload,
        roleFilterIds: newRoles,
        levelFilter: newLevels,
      };
      state.isError = null;
      state.isLoading = false;
    },
    [getCurriculumAssignmentById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [updateCurriculumAssignment.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [updateCurriculumAssignment.fulfilled]: (state, { payload }) => {
      const { roleFilterIds, levelFilter } = payload;
      const newRoles = isNilOrEmpty(roleFilterIds);
      const newLevels = isNilOrEmpty(levelFilter);

      state.isLoading = false;
      state.currentCurriculumAssign = {
        ...payload,
        roleFilterIds: newRoles,
        levelFilter: newLevels,
      };
      state.isError = null;
    },
    [updateCurriculumAssignment.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [removeCurriculumAssignment.pending]: (state) => {
      state.isError = null;
    },
    [removeCurriculumAssignment.fulfilled]: (state, { payload }) => {
      if (payload === true) {
        const index = state.curriculumAssignments.findIndex(
          (curriculumAssign) =>
            curriculumAssign.id === state.currentCurriculumAssign.id
        );
        if (index !== -1) {
          state.curriculumAssignments.splice(index, 1);
        }
      }
      state.isError = null;
    },
    [removeCurriculumAssignment.rejected]: (state, action) => {
      state.isError = action.error.message;
    },
  },
});

export const { resetCurriculum, resetEditCurriculum, selectCurriculum } =
  curriculumSlice.actions;

const { reducer } = curriculumSlice;
export default reducer;
