import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Button } from "antd";
import Spinner from "components/Spinner/Spinner.component";
import Notification from "components/Notification/Notification.component";
import ErrorMessage from "helpers/ErrorMessage";
import { StyledEditHeader } from "./LocaleEdit.styles";
import LocaleForm from "components/LocaleForm/LocaleForm";
import {
  getLocaleAssignmentById,
  resetLocale,
  updateLocaleAssignment,
} from "store/locale/localeSlice";
import { getLocales } from "store/dropdown/dropdownSlice";
import { getSchoolById } from "store/school/schoolSlice";

const LocaleEdit = () => {
  const { isLoading: schoolLoading, currentSchool } = useSelector(
    (state) => state.school
  );
  const { isLoading: dropdownLoading, locales } = useSelector(
    (state) => state.dropdown
  );
  const { isLoading, currentLocaleAssign } = useSelector(
    (state) => state.locale
  );

  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [error, setError] = useState({});
  const { schoolId, id } = useParams();

  useEffect(() => {
    dispatch(resetLocale());
    dispatch(getSchoolById({ id: schoolId }));
    dispatch(getLocales());
    dispatch(getLocaleAssignmentById({ id }));
  }, [dispatch, schoolId, id]);

  const onFinish = async (values) => {
    const res = await dispatch(
      updateLocaleAssignment({ ...values, id: parseInt(id) })
    );
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    setEditable(false);
    Notification("localeUpdated");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to={`/schools/${schoolId}`}>Back</Link>
      <StyledEditHeader>
        <h1>Edit Locale</h1>
        <span className="edit-button">
          <Button
            type="secondary"
            onClick={() => {
              setEditable(!editable);
            }}
          >
            {!editable ? "Edit" : "View"}
          </Button>
        </span>
      </StyledEditHeader>
      {schoolLoading || dropdownLoading || isLoading ? (
        <Spinner />
      ) : (
        <>
          <ErrorMessage>{error && error.message}</ErrorMessage>
          <LocaleForm
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            editable={!editable}
            formType="EDIT"
            localeData={{
              ...currentLocaleAssign,
              name: currentSchool.name,
            }}
            locales={locales}
          />
        </>
      )}
    </div>
  );
};

export default LocaleEdit;
