import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from "history";
import Login from "pages/Login/Login";
// import { refreshToken } from "store/Login/loginSlice";
import AuthenticatedRoute from "components/Shared/AuthenticatedRoute";
import LoginRedirect from "pages/LoginRedirect";
import SchoolList from "./pages/SchoolList/SchoolList";
import SchoolEdit from "pages/SchoolEdit/SchoolEdit";
import CurriculumAdd from "pages/CurriculumAdd/CurriculumAdd";
import CurriculumEdit from "pages/CurriculumEdit/CurriculumEdit";
import LocaleAdd from "pages/LocaleAdd/LocaleAdd";
import LocaleEdit from "pages/LocaleEdit/LocaleEdit";
// import CourseList from "pages/CourseList/CourseList";

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_SUBDOMAIN,
});

const ExternalLogin = () => {
  const loginUrl = process.env.REACT_APP_ADMIN_LOGIN_URL;
  useEffect(() => {
    window.location.href = loginUrl;
  }, [loginUrl]);
  return null;
};

const Routes = () => {
  const env =
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_NETLIFY === "true"
      ? "dev"
      : "prod";
  return (
    <Router history={history}>
      <Switch>
        <Route path="/redirect" component={LoginRedirect} />
        {/* <AuthenticatedRoute exact path="/courses/add" component={CourseAdd} /> */}
        <AuthenticatedRoute
          exact
          path="/schools/:id/curriculums/assign"
          component={CurriculumAdd}
        />
        <AuthenticatedRoute
          exact
          path="/schools/:schoolId/curriculums/:id"
          component={CurriculumEdit}
        />
        <AuthenticatedRoute
          exact
          path="/schools/:id/locales/assign"
          component={LocaleAdd}
        />
        <AuthenticatedRoute
          exact
          path="/schools/:schoolId/locales/:id"
          component={LocaleEdit}
        />
        <AuthenticatedRoute exact path="/schools/:id" component={SchoolEdit} />
        <AuthenticatedRoute exact path="/schools" component={SchoolList} />
        <AuthenticatedRoute exact path="/" component={SchoolList} />
        <Route
          exact
          path="/login"
          component={env === "dev" ? Login : ExternalLogin}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
