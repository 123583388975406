import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Button, Select, Col, Row } from "antd";
import { isEmpty } from "ramda";
import { useDispatch } from "react-redux";
import { getClassById, getClasses, resetClass } from "store/class/classSlice";
import {
  filterCurriculums,
  setCurriculums,
} from "store/dropdown/dropdownSlice";
import getLevels from "constants/getLevels";
import getUserRoles from "constants/getUserRoles";
import getAccessibilityTypes from "constants/getAccessibilityTypes";
import getAssignedFeatures from "constants/getAssignedFeatures";
import { curriculumAssignmentTypes } from "constants/getAssignmentTypes";
import AssignedClass from "components/AssignedClass/AssignedClass";
import AssignedIndividual from "components/AssignedIndividual/AssignedIndividual";
import Checkbox from "antd/lib/checkbox/Checkbox";

const CurriculumForm = ({
  schoolId,
  onFinish,
  onFinishFailed,
  curriculumData,
  editable,
  curriculums,
  currentClass,
  users,
  teachers,
  curriculumAssignments,
  classes,
  formType,
}) => {
  const { accessibilityType, assignmentType, assignmentRefName, feature } =
    curriculumData;
  const formEl = useRef(null);
  const dispatch = useDispatch();

  const [val, setVal] = useState({
    assignmentType: null,
    accessibilityType: null,
    assignedFeature: null,
    numberCurriculumSelected: [],
    curriculumMoreThanOne: 0,
  });

  useEffect(() => {
    setVal({
      accessibilityType,
      assignedFeature: feature,
      assignmentType,
      numberCurriculumSelected: [],
      curriculumMoreThanOne: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accessibilityType === 2 && [2, 3].includes(feature)) {
      dispatch(filterCurriculums());
    }
  }, [dispatch, accessibilityType, feature]);

  // handle changes
  const onSelectAccessibilityType = (value) => {
    let featureVal;
    if (value === 1) {
      formEl.current.setFieldsValue({ feature: 1 });
      featureVal = 1;
      dispatch(setCurriculums());
    } else {
      formEl.current.setFieldsValue({ feature: 2 });
      featureVal = 2;
      dispatch(filterCurriculums());
    }
    formEl.current.setFieldsValue({ curriculumId: [] });
    // TODOS: get all curriculums
    setVal({
      ...val,
      accessibilityType: value,
      assignedFeature: featureVal,
      curriculumMoreThanOne: 0,
    });
  };

  const onSelectAssignedFeature = (value) => {
    console.log(value);
    formEl.current.setFieldsValue({ curriculumId: [] });
    let newCurriclumMoreThanOne;
    if (value === 2 || value === 3) {
      newCurriclumMoreThanOne = 0;
      dispatch(filterCurriculums());
    } else {
      newCurriclumMoreThanOne = 4;
      dispatch(setCurriculums());
    }
    // TODOS: get all curriculums

    setVal({
      ...val,
      assignedFeature: value,
      curriculumMoreThanOne: newCurriclumMoreThanOne,
      numberCurriculumSelected: 0,
    });
  };

  const onChangeCheckDCPC = (e) => {
    formEl.current.setFieldsValue({ checkDCPC: e.target.checked });
  };

  const onChangeCurriculums = (value) => {
    formEl.current.setFieldsValue({ curriculumId: value });
    setVal({ ...val, numberCurriculumSelected: value });
  };

  const onSelectAssignmentType = (value) => {
    dispatch(resetClass());
    if (value === 5 || value === 6) {
      dispatch(getClasses({ id: schoolId, type: 1 }));
    }
    setVal({ ...val, assignmentType: value });
  };

  const onChangeCheckedClassses = (checkedValues) => {
    console.log("checked = ", checkedValues);
    formEl.current.setFieldsValue({ checkedClasses: checkedValues });
  };

  const onChangeSelectedClass = (id) => {
    dispatch(getClassById({ id }));
    // setVal({ ...val, assignmentType: 6 });
  };

  const onChangeCheckedIndividuals = (checkedValues) => {
    console.log("checked = ", checkedValues);
    formEl.current.setFieldsValue({ checkedIndividuals: checkedValues });
  };

  // checking true/false
  const checkAssignedFeature = () =>
    isEmpty(curriculumAssignments) && [2, 3].includes(val.assignedFeature);

  const getAssignmentType = (assignmentType) => {
    return curriculumAssignmentTypes.find((type) => type.id === assignmentType);
  };

  const isClassOrIndividual = (assignmentType) => {
    return ["Class", "Individual"].includes(assignmentType);
  };

  const AssignmentDetails = () => {
    const refName = getAssignmentType(assignmentType)?.name;
    return (
      <>
        <Form.Item name="assignmentType" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Assignment Type"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 6 }}
        >
          <Input value={refName} disabled={true} />
        </Form.Item>
        {isClassOrIndividual(refName) && (
          <Form.Item
            label={`${refName} Name`}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 6 }}
          >
            <Input value={assignmentRefName} disabled={true} />
          </Form.Item>
        )}
      </>
    );
  };

  return (
    <Form
      name="basic"
      ref={formEl}
      initialValues={curriculumData}
      style={{ paddingTop: "50px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item label="School Id" name="assignmentRefId" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label="School Name"
        name="name"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 6 }}
      >
        <Input disabled />
      </Form.Item>
      {formType === "EDIT" && <AssignmentDetails />}
      <Row>
        <Col span={12}>
          <Form.Item
            label="Accessibility Type"
            name="accessibilityType"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
            rules={[
              {
                required: true,
                message: "Please select accessibility type",
              },
            ]}
          >
            <Select disabled={editable} onSelect={onSelectAccessibilityType}>
              {getAccessibilityTypes.map((accessibilityType) => (
                <Select.Option
                  key={accessibilityType.id}
                  value={accessibilityType.id}
                >
                  {accessibilityType.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Choose Assigned Feature"
            name="feature"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
            noStyle={val.accessibilityType !== 2}
          >
            <Select
              disabled={editable}
              hidden={val.accessibilityType !== 2}
              onSelect={onSelectAssignedFeature}
            >
              {getAssignedFeatures.map((assignedFeature) => (
                <Select.Option
                  key={assignedFeature.id}
                  value={assignedFeature.id}
                >
                  {assignedFeature.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {checkAssignedFeature() && (
        <Row>
          <Col>
            <Form.Item name="checkDCPC" wrapperCol={{ span: 24 }}>
              <Checkbox onChange={onChangeCheckDCPC}>
                Do you want to assign curriculum as default for PC and DC?
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item
        label="Curriculum/s"
        name="curriculumId"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 6 }}
        rules={[
          {
            required: true,
            message: "Please select curriculum(s)",
          },
        ]}
      >
        <Select
          disabled={editable}
          mode={formType === "ADD" ? "multiple" : "single"}
          onChange={onChangeCurriculums}
          allowClear
        >
          {curriculums.map((curriculum) => (
            <Select.Option
              key={curriculum.id}
              value={curriculum.id}
              disabled={
                val.numberCurriculumSelected.length > val.curriculumMoreThanOne
                  ? val.numberCurriculumSelected.includes(curriculum)
                    ? false
                    : true
                  : false
              }
            >
              {curriculum.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {formType === "ADD" && (
        <Row>
          <Col span={12}>
            <Form.Item
              label="Assignment Type"
              name="assignmentType"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: "Please select assignment type",
                },
              ]}
            >
              <Select disabled={editable} onSelect={onSelectAssignmentType}>
                {curriculumAssignmentTypes.map((assignmentType) => (
                  <Select.Option
                    key={assignmentType.id}
                    value={assignmentType.id}
                  >
                    {assignmentType.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {val.assignmentType === 5 && (
              <AssignedClass
                assignedClasses={classes}
                onChangeCheckedClassses={onChangeCheckedClassses}
              />
            )}
            {val.assignmentType === 6 && (
              <AssignedIndividual
                assignedClasses={classes}
                assignedIndividuals={users}
                assignedTeachers={teachers}
                currentClass={currentClass}
                editable={editable}
                onChangeSelectedClass={onChangeSelectedClass}
                onChangeCheckedIndividuals={onChangeCheckedIndividuals}
              />
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12}>
          <Form.Item
            label="Role(s)"
            name="roleFilterIds"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
          >
            <Select mode="multiple" disabled={editable}>
              {getUserRoles.map((role) => (
                <Select.Option key={role.id} value={role.id.toString()}>
                  {role.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Level(s)"
            name="levelFilter"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
          >
            <Select mode="multiple" disabled={editable}>
              {getLevels.map((level) => (
                <Select.Option key={level.id} value={level.id.toString()}>
                  {level.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col offset={8} span={4} style={{ paddingTop: 50 }}>
          {!editable && (
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default CurriculumForm;
