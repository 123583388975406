import React from "react";
import { Layout } from "antd";
import currentYear from "helpers/currentYear";
import Sidebar from "../Sidebar/Sidebar";
import {
  DashboardLayout,
  DashboardContent,
  DashboardFooter,
} from "./Dashboard.styles";

const Dashboard = (props) => {
  const { children } = props;

  return (
    <Layout>
      <Sidebar />
      <DashboardLayout>
        <DashboardContent>{children}</DashboardContent>
        <DashboardFooter>{`Koobits ©${currentYear()}`}</DashboardFooter>
      </DashboardLayout>
    </Layout>
  );
};

export default Dashboard;
