import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchSchools,
  fetchSchoolById,
  updateSchoolData,
} from "services/schools";
import { fetchWrapper } from "services/login";
import initialSchool from "constants/initialSchool";

export const getSchools = createAsyncThunk("schools/getAll", async (params) => {
  try {
    const res = await fetchWrapper(fetchSchools, params);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? "Get all schools failed");
  }
});

export const getSchoolById = createAsyncThunk(
  "schools/getSchoolById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchSchoolById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get course failed");
    }
  }
);

export const updateSchool = createAsyncThunk(
  "schools/updateSchool",
  async (params) => {
    try {
      const res = await fetchWrapper(updateSchoolData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update course failed");
    }
  }
);

const initialState = {
  schools: [],
  metaData: {
    pageNumber: 1,
    totalItemCount: 10,
  },
  schoolsLoading: false,
  schoolsError: null,
  filteredSchools: [],
  isLoading: false,
  isError: null,
  currentSchool: initialSchool,
  updateLoading: false,
};

const schoolSlice = createSlice({
  name: "schoolSlice",
  initialState,
  reducers: {
    resetSchool: (state) => {
      state.currentSchool = initialSchool;
    },
    selectSchool: (state, { payload }) => {
      state.currentSchool = payload;
    },
  },
  extraReducers: {
    [getSchools.pending]: (state) => {
      state.schoolsLoading = true;
      state.schools = [];
      state.schoolsError = null;
    },
    [getSchools.fulfilled]: (state, { payload }) => {
      state.schoolsLoading = false;
      state.schools = payload.items;
      state.metaData = payload.metadata;
      state.schoolsError = null;
    },
    [getSchools.rejected]: (state, action) => {
      state.schoolsLoading = false;
      state.schools = [];
      state.schoolsError = action.error.message;
    },
    [getSchoolById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getSchoolById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentSchool = action.payload;
      state.isError = null;
    },
    [getSchoolById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [updateSchool.pending]: (state) => {
      state.updateLoading = true;
      state.isError = null;
    },
    [updateSchool.fulfilled]: (state, action) => {
      state.updateLoading = false;
      state.currentSchool = action.payload;
      state.isError = null;
    },
    [updateSchool.rejected]: (state, action) => {
      state.updateLoading = false;
      state.isError = action.error.message;
    },
  },
});

export const { resetSchool, selectSchool } = schoolSlice.actions;

const { reducer } = schoolSlice;
export default reducer;
