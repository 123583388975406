import React from "react";
import { Link } from "react-router-dom";
import { Button, Space } from "antd";
import styled from "styled-components";
import assignmentTypes from "constants/assignmentTypes";
import accessibilityTypes from "constants/accessibilityTypes";

const StyledDeleteButton = styled(Button)`
  padding: 0px;
  margin: 0px;
`;

const curriculumColumns = ({ onDelete, schoolId }) => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Curriculums",
    key: "curriculumName",
    dataIndex: "curriculumName",
  },
  {
    title: "Assignment Type",
    key: "assignmentType",
    render: ({ assignmentType }) => assignmentTypes[assignmentType],
  },
  {
    title: "Name",
    key: "assignmentRefName",
    dataIndex: "assignmentRefName",
  },
  {
    title: "Accessibility Type",
    key: "accessibilityType",
    render: ({ accessibilityType }) => accessibilityTypes[accessibilityType],
  },
  {
    title: "Roles",
    dataIndex: "roleFilterIds",
    key: "roleFilterIds",
  },
  {
    title: "Levels",
    dataIndex: "levelFilter",
    key: "levelFilter",
  },
  {
    title: "Action",
    key: "action",
    render: (_text, record) => {
      const { id } = record;

      return (
        <Space size="middle">
          <Link
            to={{
              pathname: `/schools/${schoolId}/curriculums/${id}`,
              state: { id },
            }}
          >
            View
          </Link>
          |
          <StyledDeleteButton
            className="delete-button"
            type="link"
            onClick={() => onDelete({ record })}
          >
            Delete
          </StyledDeleteButton>
        </Space>
      );
    },
  },
];

export default curriculumColumns;
