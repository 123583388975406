import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { getCurriculums } from "store/dropdown/dropdownSlice";

import Notification from "components/Notification/Notification.component";
import ErrorMessage from "helpers/ErrorMessage";
import CurriculumForm from "components/CurriculumForm/CurriculumForm";
import {
  addCurriculumAssignment,
  resetCurriculum,
} from "store/curriculum/curriculumSlice";
import { initialCurriculumAssign } from "constants/initialCurriculumAssign";
import { getSchoolById } from "store/school/schoolSlice";

const CurriculumAdd = () => {
  const [error, setError] = useState({});
  const { filteredCurriculums } = useSelector((state) => state.dropdown);
  const { classes, currentClass, users, teachers } = useSelector(
    (state) => state.class
  );
  const { isLoading, currentSchool } = useSelector((state) => state.school);
  const { curriculumAssignments } = useSelector((state) => state.curriculum);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(resetCurriculum());
    dispatch(getCurriculums());
    dispatch(getSchoolById({ id }));
  }, [dispatch, id]);

  const returnAssignmentRef = (values) => {
    if (values.assignmentType === 5) {
      // return classes
      return values.checkedClasses;
    } else if (values.assignmentType === 6) {
      // return individuals
      return values.checkedIndividuals;
    }
    // return school id
    return [values.assignmentRefId];
  };

  const onFinish = async (values) => {
    if (values.checkDCPC === true) {
      const features = [2, 3];
      const res = await Promise.all(
        features.map((feature) => {
          return values.curriculumId.map(async (curriculumId) => {
            await dispatch(
              addCurriculumAssignment({
                ...values,
                curriculumId,
                assignmentRefIds: returnAssignmentRef(values),
                feature: feature,
              })
            );
          });
        })
      );
      if (res.error && res.error.message) {
        setError({ message: res.error.message });
        return;
      }
    } else {
      const res = await Promise.all(
        values.curriculumId.map(async (curriculumId) => {
          await dispatch(
            addCurriculumAssignment({
              ...values,
              curriculumId,
              assignmentRefIds: returnAssignmentRef(values),
            })
          );
        })
      );
      if (res.error && res.error.message) {
        setError({ message: res.error.message });
        return;
      }
    }

    setError({});
    history.push(`/schools/${id}`);
    Notification("curriculumAdded");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to={`/schools/${id}`}>Back</Link>
      <h1>Assign Curriclum</h1>
      <ErrorMessage>{error && error.message}</ErrorMessage>
      {!isLoading && (
        <CurriculumForm
          schoolId={id}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          editable={false}
          formType="ADD"
          curriculums={filteredCurriculums}
          curriculumAssignments={curriculumAssignments}
          classes={classes}
          currentClass={currentClass}
          users={users}
          teachers={teachers}
          curriculumData={{
            name: currentSchool.name,
            assignmentRefId: currentSchool.id,
            ...initialCurriculumAssign,
          }}
        />
      )}
    </div>
  );
};

export default CurriculumAdd;
