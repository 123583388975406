import React from "react";
import { Link } from "react-router-dom";
import { Space } from "antd";

const schoolColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "School Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Action",
    key: "action",
    // eslint-disable-next-line react/display-name
    render: (_text, record) => {
      const { id } = record;
      return (
        <Space size="middle">
          <Link
            to={{
              pathname: `/schools/${id}`,
              state: { id },
            }}
          >
            View
          </Link>
        </Space>
      );
    },
  },
];

export default schoolColumns;
