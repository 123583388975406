const initialTimezone = {
  id: 132734,
  zoneName: "Asia/Singapore",
  zoneUniqueId: "342",
  zoneCode: "LMT",
  isDaylightSavings: false,
  timeStart: null,
  gmtOffset: 24925,
  status: 1,
  koobitsRelCountryZone: [],
};

export default initialTimezone;
