import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchools } from "store/school/schoolSlice";
import { Row, Col, Empty, Pagination } from "antd";
import Spinner from "../../components/Spinner/Spinner.component";
import {
  StyledHeader,
  StyledSearch,
  PaginationAlign,
} from "./SchoolList.styles";
import SchoolTable from "components/SchoolTable/SchoolTable";

const SchoolList = () => {
  const totalItemCount = 10;
  const currentPage = 1;
  const dispatch = useDispatch();
  const { schoolsLoading, schools, metaData } = useSelector(
    (state) => state.school
  );
  const [name, setName] = useState("");

  useEffect(() => {
    dispatch(getSchools({ currentPage, totalItemCount }));
  }, [dispatch]);

  const onSearch = (value) => {
    setName(value);
    if (value === "") {
      dispatch(getSchools({ currentPage, totalItemCount }));
    } else {
      dispatch(getSchools({ currentPage, totalItemCount, name: value }));
    }
  };

  const handleChange = (value) => {
    dispatch(getSchools({ currentPage: value, totalItemCount, name }));
  };

  return (
    <div className="site-layout-background">
      <h1>Schools</h1>
      <StyledHeader>
        {/* <Button type="primary">
          <Link to="/schools/add">Add</Link>
        </Button> */}
        <span></span>
        <StyledSearch placeholder="input search text" onSearch={onSearch} />
      </StyledHeader>
      {schoolsLoading ? (
        <Spinner />
      ) : (
        <>
          <Row justify="center" align="top">
            <Col span={24}>
              {schools.length > 0 && (
                <SchoolTable pagination={false} schools={schools} />
              )}
              {schools.length <= 0 && (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 60,
                    marginTop: "3rem",
                  }}
                  description={<span>No schools found</span>}
                />
              )}
            </Col>
          </Row>
          {metaData && (
            <PaginationAlign>
              <Pagination
                current={metaData.pageNumber}
                total={metaData.totalItemCount}
                defaultPageSize={metaData.pageSize}
                showSizeChanger={false}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                onChange={handleChange}
              />
            </PaginationAlign>
          )}
        </>
      )}
    </div>
  );
};

export default SchoolList;
