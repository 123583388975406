import handleResponse from "helpers/handleAPIResponse";
import store from "store/index";

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// get classes
export const fetchClasses = ({ id, type }) => {
  return fetch(`${baseUrl}/Class/${id}/${type}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all classes failed");
    });
};

// get individuals
export const fetchClassById = ({ id }) => {
  return fetch(`${baseUrl}/Class/${id}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get class by id failed");
    });
};
