import handleResponse from "helpers/handleAPIResponse";
import store from "store/index";
// import { isEmpty } from "ramda";

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// const checkEmpty = (param) => {
//   return isEmpty(param) ? "" : param.join(", ");
// };

// get locales
export const fetchLocaleAssignments = ({ assignmentId }) => {
  let endpoint = `${baseUrl}/Locale/Assignment/School/${assignmentId}`;
  return fetch(endpoint, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all locales failed"); // eslint-disable-line
    });
};

// get locale by Id
export const fetchLocaleAssignmentById = ({ id }) => {
  return fetch(`${baseUrl}/Locale/Assignment/${id}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get locale by id failed");
    });
};

// add locale
export const createLocaleAssignment = (params) => {
  // const {
  //   roleFilterIds,
  //   levelFilter,
  //   localeId,
  //   assignmentType,
  //   assignmentRefId,
  // } = params;
  // const newRoleFilterIds = checkEmpty(roleFilterIds);
  // const newLevelFilter = checkEmpty(levelFilter);

  // const newParams = {
  //   localeId,
  //   assignmentType,
  //   assignmentRefId,
  //   roleFilterIds: newRoleFilterIds,
  //   levelFilter: newLevelFilter,
  // };
  return fetch(`${baseUrl}/Locale/Assignment`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Add locale by id failed");
    });
};

// update locale
export const patchLocaleAssignment = (params) => {
  const { id } = params;
  // const {
  //   id,
  //   roleFilterIds,
  //   levelFilter,
  //   curriculumId,
  //   accessibilityType,
  //   assignmentType,
  //   assignmentRefId,
  //   feature,
  // } = params;
  // const newRoleFilterIds = checkEmpty(roleFilterIds);
  // const newLevelFilter = checkEmpty(levelFilter);

  // const newParams = {
  //   id,
  //   curriculumId,
  //   accessibilityType,
  //   feature,
  //   assignmentType,
  //   assignmentRefId,
  //   roleFilterIds: newRoleFilterIds,
  //   levelFilter: newLevelFilter,
  // };

  return fetch(`${baseUrl}/Locale/Assignment/${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update locale by id failed");
    });
};

// remove locale by Id
export const deleteLocaleAssignment = ({ id }) => {
  return fetch(`${baseUrl}/Locale/Assignment/${id}`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Delete locale by id failed");
    });
};
