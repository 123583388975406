const getLevels = [
  {
    id: 1,
    name: "Primary 1",
    description: "Primary 1",
    ageGroup: 0,
    status: 1,
  },
  {
    id: 2,
    name: "Primary 2",
    description: "Primary 2",
    ageGroup: 0,
    status: 1,
  },
  {
    id: 3,
    name: "Primary 3",
    description: "Primary 3",
    ageGroup: 0,
    status: 1,
  },
  {
    id: 4,
    name: "Primary 4",
    description: "Primary 4",
    ageGroup: 0,
    status: 1,
  },
  {
    id: 5,
    name: "Primary 5",
    description: "Primary 5",
    ageGroup: 0,
    status: 1,
  },
  {
    id: 6,
    name: "Primary 6",
    description: "Primary 6",
    ageGroup: 0,
    status: 1,
  },
];

export default getLevels;
