import { Form, Checkbox, Select, Input } from "antd";
import { isNil } from "ramda";

const AssignedIndividual = ({
  editable,
  currentClass,
  assignedClasses,
  assignedIndividuals,
  assignedTeachers,
  onChangeSelectedClass,
  onChangeCheckedIndividuals,
}) => {
  const displayStudentHeading = () =>
    assignedIndividuals.length > 0 && <h4>Students</h4>;

  const displayTeacherHeading = () =>
    assignedTeachers.length > 0 && (
      <div style={{ paddingTop: 20 }}>
        <h4>Teachers</h4>
      </div>
    );

  return (
    <>
      <Form.Item name="checkedIndividuals" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label="Select the Class"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
      >
        <Select
          disabled={editable}
          onChange={onChangeSelectedClass}
          showSearch
          filterOption={(input, { props }) =>
            props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {assignedClasses.map((assignedClass) => (
            <Select.Option key={assignedClass.id} value={assignedClass.id}>
              {assignedClass.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <br></br>
      <Form.Item
        label={!isNil(currentClass.id) && "Please select individuals"}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
      >
        {displayStudentHeading()}
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={onChangeCheckedIndividuals}
        >
          {assignedIndividuals.map((assignedInd) => (
            <div key={assignedInd.id}>
              <Checkbox value={assignedInd.id}>{assignedInd.username}</Checkbox>
              <br></br>
            </div>
          ))}
          {displayTeacherHeading()}
          {assignedTeachers.map((teacher) => (
            <div key={teacher.id}>
              <Checkbox value={teacher.id}>{teacher.username}</Checkbox>
              <br></br>
            </div>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </>
  );
};

export default AssignedIndividual;
