const curriculumAssignmentTypes = [
  { id: 3, name: "School" },
  { id: 5, name: "Class" },
  { id: 6, name: "Individual" },
];

const localeAssignmentTypes = [
  { id: 3, name: "School" },
  { id: 6, name: "Individual" },
];

export { curriculumAssignmentTypes, localeAssignmentTypes };
