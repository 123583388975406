const defaultLocales = [
  {
    id: 445,
    name: "en",
    displayName: "English",
    nativeName: "English",
    englishName: "English",
    localeId: 0,
    assignmentType: 0,
    assignmentReferenceId: 0,
    assignmentRefName: null,
  },
  {
    id: 695,
    name: "id",
    displayName: "Indonesian",
    nativeName: "Indonesia",
    englishName: "Indonesian",
    localeId: 0,
    assignmentType: 0,
    assignmentReferenceId: 0,
    assignmentRefName: null,
  },
  {
    id: 285,
    name: "zh-TW",
    displayName: "Chinese (Traditional, Taiwan)",
    nativeName: "中文(台灣)",
    englishName: "Chinese (Traditional, Taiwan)",
    localeId: 0,
    assignmentType: 0,
    assignmentReferenceId: 0,
    assignmentRefName: null,
  },
  {
    id: 277,
    name: "zh-CN",
    displayName: "Chinese (Simplified, China)",
    nativeName: "中文(中国)",
    englishName: "Chinese (Simplified, China)",
    localeId: 0,
    assignmentType: 0,
    assignmentReferenceId: 0,
    assignmentRefName: null,
  },
];

export default defaultLocales;
