import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Select, Col, Row } from "antd";
import AssignedIndividual from "components/AssignedIndividual/AssignedIndividual";
import { getClassById, getClasses, resetClass } from "store/class/classSlice";
import { localeAssignmentTypes } from "constants/getAssignmentTypes";
import defaultLocales from "constants/defaultLocales";

const LocaleForm = ({
  schoolId,
  onFinish,
  onFinishFailed,
  localeData,
  editable,
  locales,
  formType,
  classes,
  users,
  teachers,
  currentClass,
}) => {
  const { assignmentType, assignmentRefName } = localeData;
  const dispatch = useDispatch();
  const formEl = useRef(null);
  const [val, setVal] = useState({
    assignmentType: null,
    numberLocaleSelected: [],
    curriculumMoreThanOne: 2,
  });

  // handle changes
  const onSelectAssignmentType = (value) => {
    if (value === 6) {
      dispatch(resetClass());
      dispatch(getClasses({ id: schoolId, type: 1 }));
    }
    setVal({ ...val, assignmentType: value });
  };

  const onChangeSelectedClass = (id) => {
    dispatch(getClassById({ id }));
    // setVal({ ...val, assignmentType: 6 });
  };

  const onChangeCheckedIndividuals = (checkedValues) => {
    console.log("checked = ", checkedValues);
    formEl.current.setFieldsValue({ checkedIndividuals: checkedValues });
  };
  // checking true/false
  const getAssignmentType = (assignmentType) => {
    return localeAssignmentTypes.find((type) => type.id === assignmentType);
  };

  const isIndividual = (assignmentType) => {
    return ["Individual"].includes(assignmentType);
  };

  const AssignmentDetails = () => {
    const refName = getAssignmentType(assignmentType)?.name;

    return (
      <>
        <Form.Item
          label="Assignment Ref Id"
          name="assignmentReferenceId"
          noStyle
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Assignment Type"
          name="assignmentType"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 6 }}
        >
          <Select disabled>
            {localeAssignmentTypes.map((assignmentType) => (
              <Select.Option key={assignmentType.id} value={assignmentType.id}>
                {assignmentType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {isIndividual(refName) && (
          <Form.Item
            label={`${refName} Name`}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 6 }}
          >
            <Input value={assignmentRefName} disabled={true} />
          </Form.Item>
        )}
      </>
    );
  };

  return (
    <Form
      name="basic"
      ref={formEl}
      initialValues={{ localeId: defaultLocales[0].id, ...localeData }}
      style={{ paddingTop: "50px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {formType === "EDIT" && <AssignmentDetails />}
      <Form.Item
        label="School name"
        name="name"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 6 }}
      >
        <Input disabled={true} />
      </Form.Item>
      <Form.Item
        label="Locale"
        name="localeId"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 6 }}
        rules={[
          {
            required: true,
            message: "Please select locale",
          },
        ]}
      >
        <Select
          disabled={editable}
          showSearch
          filterOption={(input, { props }) =>
            props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {locales.map((locale) => (
            <Select.Option key={locale.id} value={locale.id}>
              {locale.displayName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {formType === "ADD" && (
        <Row>
          <Col span={12}>
            <Form.Item
              label="Assignment Type"
              name="assignmentType"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: "Please select assignment type",
                },
              ]}
            >
              <Select disabled={editable} onSelect={onSelectAssignmentType}>
                {localeAssignmentTypes.map((assignmentType) => (
                  <Select.Option
                    key={assignmentType.id}
                    value={assignmentType.id}
                  >
                    {assignmentType.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {val.assignmentType === 6 && (
              <AssignedIndividual
                assignedClasses={classes}
                assignedIndividuals={users}
                assignedTeachers={teachers}
                currentClass={currentClass}
                editable={editable}
                onChangeSelectedClass={onChangeSelectedClass}
                onChangeCheckedIndividuals={onChangeCheckedIndividuals}
              />
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col offset={8} span={4} style={{ paddingTop: 50 }}>
          {!editable && (
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default LocaleForm;
