export const initialCurriculumAssign = {
  accessibilityType: null,
  feature: null,
  roleFilterIds: [],
  levelFilter: [],
  curriculumId: [],
};

export const initialEditCurriculumAssign = {
  accessibilityType: null,
  feature: null,
  roleFilterIds: [],
  levelFilter: [],
  curriculumId: null,
};

export default initialCurriculumAssign;
