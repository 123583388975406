import handleResponse from "helpers/handleAPIResponse";
import store from "store/index";

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// get courses
export const fetchCountries = () => {
  return fetch(`${baseUrl}/Country`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all countries failed");
    });
};

// get course by Id
export const fetchCountryById = ({ courseId }) => {
  return fetch(`${baseUrl}/Country/${courseId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get course by id failed");
    });
};

// get timezones
export const fetchTimezones = ({ countryId }) => {
  return fetch(`${baseUrl}/Timezone/country/${countryId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all timezones failed");
    });
};

// get timezone by Id
export const fetchTimezoneById = ({ timezoneId }) => {
  return fetch(`${baseUrl}/Country/${timezoneId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get timezone by id failed");
    });
};

// get curriculums
export const fetchCurriculums = () => {
  return fetch(`${baseUrl}/Curriculums`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all timezones failed");
    });
};

// get curriculum by Id
export const fetchCurriculumById = ({ id }) => {
  return fetch(`${baseUrl}/Curriculums/${id}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get timezone by id failed");
    });
};

// get locales
export const fetchLocales = () => {
  return fetch(`${baseUrl}/Locale/1/1000`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message.Message);
      } else {
        return result.items;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all timezones failed");
    });
};

// get locale by Id
export const fetchLocaleById = ({ id }) => {
  return fetch(`${baseUrl}/Locales/${id}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get timezone by id failed");
    });
};
