import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import loginReducer from "store/login/loginSlice";
import courseReducer from "store/course/courseSlice";
import dropdownReducer from "store/dropdown/dropdownSlice";
import schoolReducer from "store/school/schoolSlice";
import curriculumReducer from "store/curriculum/curriculumSlice";
import classReducer from "store/class/classSlice";
import localeReducer from "store/locale/localeSlice";

const middleware = [
  /* YOUR CUSTOM MIDDLEWARES HERE */
  ...getDefaultMiddleware(),
];

const combinedReducer = combineReducers({
  login: loginReducer,
  course: courseReducer,
  dropdown: dropdownReducer,
  school: schoolReducer,
  curriculum: curriculumReducer,
  locale: localeReducer,
  class: classReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "reset-store") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;
