import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createLocaleAssignment,
  deleteLocaleAssignment,
  fetchLocaleAssignmentById,
  fetchLocaleAssignments,
  patchLocaleAssignment,
} from "services/assignLocales";
import { fetchWrapper } from "services/login";
import initialLocaleAssign from "constants/initialLocaleAssign";

export const getLocaleAssignments = createAsyncThunk(
  "localeAssignments/getAll",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchLocaleAssignments, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get all locale assign. failed");
    }
  }
);

export const getLocaleAssignmentById = createAsyncThunk(
  "localeAssignments/getById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchLocaleAssignmentById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get locale assign id failed");
    }
  }
);

export const addLocaleAssignment = createAsyncThunk(
  "locales/addLocaleAssignment",
  async (params) => {
    try {
      const res = await fetchWrapper(createLocaleAssignment, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Add locale assign failed");
    }
  }
);

export const updateLocaleAssignment = createAsyncThunk(
  "locales/updateLocaleAssignment",
  async (params) => {
    try {
      const res = await fetchWrapper(patchLocaleAssignment, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update locale assign failed");
    }
  }
);
export const removeLocaleAssignment = createAsyncThunk(
  "locales/removeLocaleAssignment",
  async (params) => {
    try {
      const res = await fetchWrapper(deleteLocaleAssignment, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Remove locale assign failed");
    }
  }
);

const initialState = {
  localeAssignments: [],
  isLoading: false,
  isError: null,
  currentLocaleAssign: initialLocaleAssign,
};

const localeSlice = createSlice({
  name: "localeSlice",
  initialState,
  reducers: {
    resetLocale: (state) => {
      state.currentLocaleAssign = initialLocaleAssign;
    },
    selectLocale: (state, { payload }) => {
      state.currentLocaleAssign = payload;
    },
  },
  extraReducers: {
    [getLocaleAssignments.pending]: (state) => {
      state.isLoading = true;
      state.localeAssignments = [];
      state.isError = null;
    },
    [getLocaleAssignments.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.localeAssignments = payload;
      state.isError = null;
    },
    [getLocaleAssignments.rejected]: (state, action) => {
      state.isLoading = false;
      state.localeAssignments = [];
      state.isError = action.error.message;
    },
    [getLocaleAssignmentById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getLocaleAssignmentById.fulfilled]: (state, { payload }) => {
      state.currentLocaleAssign = payload;
      state.isError = null;
      state.isLoading = false;
    },
    [getLocaleAssignmentById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [updateLocaleAssignment.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [updateLocaleAssignment.fulfilled]: (state, { payload }) => {
      state.currentLocaleAssign = payload;
      state.isError = null;
      state.isLoading = false;
    },
    [updateLocaleAssignment.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [removeLocaleAssignment.pending]: (state) => {
      state.isError = null;
    },
    [removeLocaleAssignment.fulfilled]: (state, { payload }) => {
      if (payload === true) {
        const index = state.localeAssignments.findIndex(
          (localAssign) => localAssign.id === state.currentLocaleAssign.id
        );
        if (index !== -1) {
          state.localeAssignments.splice(index, 1);
        }
      }
      state.isError = null;
    },
    [removeLocaleAssignment.rejected]: (state, action) => {
      state.isError = action.error.message;
    },
  },
});

export const { resetLocale, selectLocale } = localeSlice.actions;

const { reducer } = localeSlice;
export default reducer;
