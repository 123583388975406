import { Input } from "antd";
import styled from "styled-components";

const { Search } = Input;

export const StyledHeader = styled.div`
  display: flex;
  padding-top: 30px;
  justify-content: space-between;
`;

export const StyledSearch = styled(Search)`
  width: 200px;
  padding-bottom: 10px;
`;

export const PaginationAlign = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
