const initialSchool = {
  id: null,
  name: null,
  code: null,
  type: 0,
  countryId: null,
  countryZoneId: null,
  locales: null,
  users: null,
};

export default initialSchool;
