import styled from "styled-components";

export const StyledHeader = styled.div`
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-between;
`;

export const StyledEditHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;
