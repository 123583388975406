import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCountries,
  fetchCountryById,
  fetchTimezones,
  fetchTimezoneById,
  fetchCurriculums,
  fetchCurriculumById,
  fetchLocaleById,
} from "services/dropdowns";
import { fetchWrapper } from "services/login";
import initialCountry from "constants/initialCountry";
import initialTimezone from "constants/initialTimezone";
import defaultLocales from "constants/defaultLocales";

export const getCountries = createAsyncThunk("countries/getAll", async () => {
  try {
    const res = await fetchWrapper(fetchCountries);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? "Get all countries failed");
  }
});

export const getCountryById = createAsyncThunk(
  "countries/getCountryById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchCountryById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get country failed");
    }
  }
);

export const getTimezones = createAsyncThunk(
  "timezones/getAll",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchTimezones, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get all timezones failed");
    }
  }
);

export const getTimezoneById = createAsyncThunk(
  "timezones/getTimezoneById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchTimezoneById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get timezone failed");
    }
  }
);

export const getCurriculums = createAsyncThunk(
  "curriculums/getAll",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchCurriculums, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get all curriculums failed");
    }
  }
);

export const getCurriculumById = createAsyncThunk(
  "curriculums/getCurriculumById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchCurriculumById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get curriculum failed");
    }
  }
);

export const getLocales = createAsyncThunk("locales/getAll", async () => {
  try {
    // const res = await fetchWrapper(fetchLocales);
    const res = defaultLocales;
    return res;
  } catch (error) {
    throw new Error(error?.message ?? "Get all locales failed");
  }
});

export const getLocaleById = createAsyncThunk(
  "locales/getLocaleById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchLocaleById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get locale failed");
    }
  }
);

const initialState = {
  countries: [],
  timezones: [],
  curriculums: [],
  filteredCurriculums: [],
  locales: [],
  isLoading: false,
  isError: null,
  currentCountry: initialCountry,
  currentTimezone: initialTimezone,
};

const countrySlice = createSlice({
  name: "countrySlice",
  initialState,
  reducers: {
    filterCurriculums: (state) => {
      state.filteredCurriculums = state.curriculums.filter(
        (one) => one.levels.length > 0
      );
    },
    setCurriculums: (state) => {
      state.filteredCurriculums = state.curriculums;
    },
    resetCountry: (state) => {
      state.currentCountry = initialCountry;
    },
    selectCountry: (state, { payload }) => {
      state.currentCountry = payload;
    },
  },
  extraReducers: {
    [getCountries.pending]: (state) => {
      state.isLoading = true;
      state.countries = [];
      state.isError = null;
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.countries = payload;
      state.isError = null;
    },
    [getCountries.rejected]: (state, action) => {
      state.isLoading = false;
      state.countries = [];
      state.isError = action.error.message;
    },
    [getCountryById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getCountryById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentCountry = action.payload;
      state.isError = null;
    },
    [getCountryById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [getTimezones.pending]: (state) => {
      // state.isLoading = true;
      state.timezones = [];
      state.isError = null;
    },
    [getTimezones.fulfilled]: (state, { payload }) => {
      // state.isLoading = false;
      state.timezones = payload;
      state.isError = null;
    },
    [getTimezones.rejected]: (state, action) => {
      // state.isLoading = false;
      state.timezones = [];
      state.isError = action.error.message;
    },
    [getTimezoneById.pending]: (state) => {
      // state.isLoading = true;
      state.isError = null;
    },
    [getTimezoneById.fulfilled]: (state, action) => {
      // state.isLoading = false;
      state.currentTimezone = action.payload;
      state.isError = null;
    },
    [getTimezoneById.rejected]: (state, action) => {
      // state.isLoading = false;
      state.isError = action.error.message;
    },
    [getCurriculums.pending]: (state) => {
      // state.isLoading = true;
      state.curriculums = [];
      state.filteredCurriculums = [];
      state.isError = null;
    },
    [getCurriculums.fulfilled]: (state, { payload }) => {
      // state.isLoading = false;
      state.curriculums = payload;
      state.filteredCurriculums = payload;
      state.isError = null;
    },
    [getCurriculums.rejected]: (state, action) => {
      // state.isLoading = false;
      state.curriculums = [];
      state.filteredCurriculums = [];
      state.isError = action.error.message;
    },
    [getLocales.pending]: (state) => {
      // state.isLoading = true;
      state.locales = [];
      state.isError = null;
    },
    [getLocales.fulfilled]: (state, { payload }) => {
      // state.isLoading = false;
      state.locales = payload;
      state.isError = null;
    },
    [getLocales.rejected]: (state, action) => {
      // state.isLoading = false;
      state.locales = [];
      state.isError = action.error.message;
    },
  },
});

export const {
  resetCountry,
  selectCountry,
  filterCurriculums,
  setCurriculums,
} = countrySlice.actions;

const { reducer } = countrySlice;
export default reducer;
