import React from "react";
import { Table } from "antd";
import schoolColumns from "helpers/Columns/schoolColumns";

// const Link = (text, record) => {
//   const history = useHistory();
//   return (
//     <Button
//       onClick={(e) => {
//         e.preventDefault();
//         history.push(`/notification/${record.id}`);
//       }}
//       type="link"
//     >
//       {text}
//     </Button>
//   );
// };

// const Actions = (record) => {
//   const history = useHistory();
//   const dispatch = useDispatch();
//   const { notifications } = useSelector((state) => state.notification);

//   const { metaData } = notifications;

//   return (
//     <>
//       <Button
//         onClick={(e) => {
//           e.preventDefault();
//           history.push(`/notification/${record.id}`);
//         }}
//         type="link"
//       >
//         View
//       </Button>
//       <Button
//         onClick={(e) => {
//           e.preventDefault();
//           // history.push(`/notification/${record.key}`);
//           dispatch(selectNotification(record));
//           const message = window.confirm("Do you really want to remove this?");
//           if (message === true) {
//             dispatch(removeNotification(record.id)).then(() => {
//               Notification("notificationRemoved");
//               dispatch(
//                 getNotifications({
//                   currentPage: metaData.pageNumber,
//                   totalItemCount: 10,
//                 })
//               );
//             });
//           }
//         }}
//         type="link"
//       >
//         Delete
//       </Button>
//     </>
//   );
// };

const SchoolTable = ({ pagination, schools }) => {
  return (
    <Table
      rowKey="id"
      columns={schoolColumns}
      pagination={pagination}
      dataSource={schools}
      style={{ marginTop: "2rem" }}
    />
  );
};
export default SchoolTable;
