import handleResponse from "helpers/handleAPIResponse";
import { isNil } from "ramda";
import store from "store/index";

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// get schools
export const fetchSchools = ({ currentPage, totalItemCount, name }) => {
  let endpoint = `${baseUrl}/School/${currentPage}/${totalItemCount}`;
  if (!isNil(name)) {
    endpoint = `${baseUrl}/School/${currentPage}/${totalItemCount}?name=${name}`;
  }

  return fetch(endpoint, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all schools failed"); // eslint-disable-line
    });
};

// get school by Id
export const fetchSchoolById = ({ id }) => {
  return fetch(`${baseUrl}/School/${id}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get school by id failed"); // eslint-disable-line
    });
};

export const updateSchoolData = (params) => {
  const { id } = params;
  const newParams = { ...params, id: parseInt(id) };
  return fetch(`${baseUrl}/School/${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(newParams),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.message.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update school failed"); // eslint-disable-line
    });
};
