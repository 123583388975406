import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Notification from "components/Notification/Notification.component";
import ErrorMessage from "helpers/ErrorMessage";
import LocaleForm from "components/LocaleForm/LocaleForm";
import initialLocaleAssign from "constants/initialLocaleAssign";
import { addLocaleAssignment, resetLocale } from "store/locale/localeSlice";
import { getLocales } from "store/dropdown/dropdownSlice";

const LocaleAdd = () => {
  const { currentSchool } = useSelector((state) => state.school);
  const { classes, currentClass, users, teachers } = useSelector(
    (state) => state.class
  );
  const { isLoading, locales } = useSelector((state) => state.dropdown);
  const [error, setError] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    setError({});
    dispatch(resetLocale());
    dispatch(getLocales());
  }, [dispatch]);

  const returnAssignmentRef = (values) => {
    if (values.assignmentType === 6) {
      // return individuals
      return values.checkedIndividuals;
    }
    // return school id
    return [parseInt(id)];
  };

  const onFinish = async (values) => {
    const res = await dispatch(
      addLocaleAssignment({
        ...values,
        assignmentReferenceId: returnAssignmentRef(values),
      })
    );
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    history.push(`/schools/${id}`);
    Notification("localeAdded");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to={`/schools/${id}`}>Back</Link>
      <h1>Assign Locale</h1>
      <ErrorMessage>{error && error.message}</ErrorMessage>
      {!isLoading && (
        <LocaleForm
          schoolId={id}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          editable={false}
          formType="ADD"
          locales={locales}
          classes={classes}
          currentClass={currentClass}
          users={users}
          teachers={teachers}
          localeData={{
            ...initialLocaleAssign,
            name: currentSchool.name,
          }}
        />
      )}
    </div>
  );
};

export default LocaleAdd;
