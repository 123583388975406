import handleResponse from "helpers/handleAPIResponse";
import { isEmpty } from "ramda";
import store from "store/index";

const baseUrl = process.env.REACT_APP_API_DOMAIN;

const checkEmpty = (param) => {
  return isEmpty(param) ? "" : param.join(", ");
};

// get curriculums
export const fetchCurriculumAssignments = ({
  assignmentType,
  assignmentId,
}) => {
  let endpoint = `${baseUrl}/Curriculum/Assignments/${assignmentType}/${assignmentId}`;
  return fetch(endpoint, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all curriculums failed");
    });
};

// get curriculum by Id
export const fetchCurriculumAssignmentById = ({ id }) => {
  return fetch(`${baseUrl}/Curriculum/Assignment/${id}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get curriculum by id failed");
    });
};

// add curriculum
export const createCurriculumAssignment = (params) => {
  const {
    roleFilterIds,
    levelFilter,
    curriculumId,
    accessibilityType,
    assignmentType,
    assignmentRefIds,
    feature,
  } = params;
  const newRoleFilterIds = checkEmpty(roleFilterIds);
  const newLevelFilter = checkEmpty(levelFilter);

  const newParams = {
    curriculumId,
    accessibilityType,
    feature,
    assignmentType,
    assignmentRefIds,
    roleFilterIds: newRoleFilterIds,
    levelFilter: newLevelFilter,
  };
  console.log("newParams", newParams);
  return fetch(`${baseUrl}/Curriculum/Assignment`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(newParams),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Add curriculum by id failed");
    });
};

// update curriculum
export const patchCurriculumAssignment = (params) => {
  const {
    id,
    roleFilterIds,
    levelFilter,
    curriculumId,
    accessibilityType,
    assignmentType,
    assignmentRefId,
    feature,
  } = params;
  const newRoleFilterIds = checkEmpty(roleFilterIds);
  const newLevelFilter = checkEmpty(levelFilter);

  const newParams = {
    id,
    curriculumId,
    accessibilityType,
    feature,
    assignmentType,
    assignmentRefId,
    roleFilterIds: newRoleFilterIds,
    levelFilter: newLevelFilter,
  };
  console.log("newParams", newParams);
  return fetch(`${baseUrl}/Curriculum/Assignment/${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(newParams),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update curriculum by id failed");
    });
};

// remove curriculum by Id
export const deleteCurriculumAssignment = ({ id }) => {
  return fetch(`${baseUrl}/Curriculum/Assignment/${id}`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result) {
        throw new Error(result.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Delete curriculum by id failed");
    });
};
